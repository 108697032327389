import { BrowserRouter as Router } from 'react-router-dom';
import styled from '@emotion/styled';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Favorites from './components/Favorites';
import SteamProfile from './components/SteamProfile';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import CursorEffect from './components/CursorEffect';
import { Global, css } from '@emotion/react';

const globalStyles = css`
  ::selection {
    background-color: #0066ff40;
    color: #00ccff;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #000913;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0066ff;
    border: 3px solid #000913;
    border-radius: 0;
  }

  @keyframes glitch {
    0% {
      text-shadow: 0.05em 0 0 #00b3ff, -0.03em -0.04em 0 #0066ff,
                   0.025em 0.04em 0 #0035ff;
    }
    15% {
      text-shadow: 0.05em 0 0 #00b3ff, -0.03em -0.04em 0 #0066ff,
                   0.025em 0.04em 0 #0035ff;
    }
    16% {
      text-shadow: -0.05em -0.025em 0 #00b3ff, 0.025em 0.035em 0 #0066ff,
                   -0.05em -0.05em 0 #0035ff;
    }
    49% {
      text-shadow: -0.05em -0.025em 0 #00b3ff, 0.025em 0.035em 0 #0066ff,
                   -0.05em -0.05em 0 #0035ff;
    }
    50% {
      text-shadow: 0.05em 0.035em 0 #00b3ff, 0.03em 0 0 #0066ff,
                   0 -0.04em 0 #0035ff;
    }
  }

  @keyframes matrix-rain {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @font-face {
    font-family: 'Press Start 2P';
    src: url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
  }

  * {
    cursor: default;
  }

  a, button, [role="button"] {
    cursor: pointer;
    &:hover ~ .cursor {
      transform: scale(1.5);
    }
  }

  @keyframes pulseRing {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
`;

const AppContainer = styled.div`
  padding-top: 80px;
  background: #000913;
  color: #00ccff;
  min-height: 100vh;
  font-family: 'Share Tech Mono', monospace;
  position: relative;
  
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(90deg, #00061580 1px, transparent 1px),
      linear-gradient(#00061580 1px, transparent 1px);
    background-size: 30px 30px;
    pointer-events: none;
    animation: matrix-rain 20s linear infinite;
    z-index: 1;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 102, 255, 0.1),
      rgba(0, 0, 0, 0.2)
    );
    pointer-events: none;
    z-index: 2;
  }

  & > * {
    position: relative;
    z-index: 3;
  }
`;

function App() {
  return (
    <Router>
      <Global styles={globalStyles} />
      <AppContainer>
        <CursorEffect />
        <Navigation />
        <Hero />
        <Projects />
        <Skills />
        <Favorites />
        <SteamProfile />
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
