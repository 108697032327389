import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const SkillsSection = styled.section`
  min-height: 100vh;
  padding: 100px 10%;
  background: #0a192f;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  color: #64ffda;
  margin-bottom: 3rem;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const SkillCategory = styled(motion.div)`
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #00ff00;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.2);
  
  &::before {
    content: 'ACCESS LEVEL: CLASSIFIED';
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: #00ff00;
    font-size: 0.8rem;
    opacity: 0.5;
  }
`;

const SkillList = styled.ul`
  list-style: none;
  padding: 0;
  
  li {
    color: #8892b0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }
`;

const SkillItem = styled(motion.li)`
  color: #00ff00;
  font-family: 'Share Tech Mono', monospace;
  margin-bottom: 0.5rem;
  
  &::before {
    content: '$ sudo apt-get install';
    color: #00ff00;
    opacity: 0.7;
    margin-right: 1rem;
  }
`;

function Skills() {
  const skillCategories = [
    {
      title: "フロントエンド",
      skills: ["React", "TypeScript", "Next.js", "Vue.js"]
    },
    {
      title: "バックエンド",
      skills: ["Node.js", "Python", "Java", "SQL"]
    },
    // 他のカテゴリー...
  ];

  return (
    <SkillsSection id="skills">
      <SectionTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        スキル
      </SectionTitle>
      <SkillsGrid>
        {skillCategories.map((category, index) => (
          <SkillCategory
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ y: -5 }}
          >
            <div className="line-numbers">
              {category.skills.map((_, i) => (
                <div key={i}>{i + 1}</div>
              ))}
            </div>
            <h3>{category.title}</h3>
            <SkillList>
              {category.skills.map((skill, i) => (
                <SkillItem
                  key={i}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: (index * 0.1) + (i * 0.1) }}
                >
                  {skill} <span className="from">from</span> 'experience'
                </SkillItem>
              ))}
            </SkillList>
          </SkillCategory>
        ))}
      </SkillsGrid>
    </SkillsSection>
  );
}

export default Skills; 