export const projects = [
  {
    title: "Pio2 Clan Dashboard",
    description: "ゲームクランメンバーのスタッツを可視化するダッシュボード。ランク帯やKD比などの統計情報をリアルタイムで表示し、メンバー管理を効率化。",
    image: "/images/pio2clandashboard.png",
    tech: ["Next.js", "TypeScript", "TailwindCSS", "Supabase"],
    live: "https://dashboard.pio2clan.com",
    stats: {
      level: "Lv.78",
      difficulty: "★★★★☆"
    }
  },
  {
    title: "Pio2 Clan Portal",
    description: "クランメンバー専用の情報ポータルサイト。メンバー情報、アクティビティ、通話情報などの詳細なデータを一元管理し、クランの活性化を支援。",
    image: "/images/pio2clan-portal.png",
    tech: ["Next.js", "TypeScript", "TailwindCSS", "Firebase"],
    live: "https://8102clan.net",
    stats: {
      level: "Lv.82",
      difficulty: "★★★★☆"
    }
  },
]; 