export const favorites = {
  anime: [
    {
      title: "この素晴らしい世界に祝福を！",
      image: "/images/favorites/a.png",
      comment: "ギャグとファンタジーの絶妙なバランスが最高の作品。特に雨宮天さん演じるアクアの演技の振り幅が素晴らしく、気の抜けた女神から本気で泣くシーンまで、すべての演技に引き込まれます。アクアのキャラソンも最高です！",
      tags: ["コメディ", "ファンタジー", "異世界", "雨宮天", "アクア様"]
    }
  ],
  games: [
    {
      title: "Rocket League",
      image: "/images/favorites/rocketleague.png",
      comment: "サッカーと車の異色の組み合わせながら、奥深い物理演算とテクニカルな操作性が魅力。何年プレイしても新しい発見がある神ゲー。",
      platform: "PC/PS/Xbox/Switch",
      tags: ["カーサッカー", "e-Sports", "物理演算", "マルチプレイ"]
    }
  ]
}; 