import styled from '@emotion/styled';
import { motion, useMotionValue, useTransform, useSpring, useAnimation } from 'framer-motion';
import { useRef, useState, memo, useCallback } from 'react';
import profileImage from '../assets/poteeeet.png';

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 10%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 80px;
    min-height: 100vh;
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(135deg, #000913 0%, #001233 100%);
    z-index: -1;
  }
`;

const BackgroundGrid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(90deg, rgba(0, 204, 255, 0.03) 1px, transparent 1px),
    linear-gradient(rgba(0, 204, 255, 0.03) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(500px) rotateX(30deg);
  transform-origin: 50% 0;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 204, 255, 0.1),
      transparent 70%
    );
  }
`;

const BackgroundLines = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  opacity: 0.3;
  will-change: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
    background: linear-gradient(
      transparent,
      rgba(0, 204, 255, 0.05) 30%,
      rgba(0, 204, 255, 0.05) 70%,
      transparent
    );
    animation: rotate 30s linear infinite;
    will-change: transform;
  }

  &::after {
    transform: rotate(30deg);
    animation-duration: 40s;
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const HeroContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
  }
`;

const HeroLeft = styled.div`
  width: 500px;
  flex-shrink: 0;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const HeroRight = styled.div`
  flex: 1;
  position: relative;
  min-height: 400px;
  margin-right: -10%;
  
  @media (max-width: 1024px) {
    min-height: 300px;
    margin-right: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    min-height: 250px;
  }
`;

const AnimeDecoration = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 500px;
  background: rgba(0, 9, 19, 0.3);
  border-radius: 12px 0 0 12px;
  overflow: hidden;
  
  @media (max-width: 1024px) {
    height: 300px;
    border-radius: 12px;
  }

  @media (max-width: 768px) {
    height: 250px;
  }
  
  &::before {
    content: 'PLAYGROUND';
    position: absolute;
    top: 10px;
    left: 10px;
    font-family: 'Press Start 2P', cursive;
    font-size: 0.8rem;
    color: #00ccff;
    opacity: 0.5;
  }
`;

const AnimeStats = styled.div`
  position: absolute;
  top: -60px;
  right: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  color: #00ccff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  .stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    .label {
      color: #0066ff;
    }
    
    .bar {
      width: 100px;
      height: 4px;
      background: rgba(0, 102, 255, 0.2);
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #00ccff;
        animation: fillBar 2s ease-out forwards;
      }
    }
  }
  
  @keyframes fillBar {
    from { width: 0; }
    to { width: 90%; }
  }
`;

const DecoImage = styled(motion.div)`
  position: absolute;
  width: 80px;
  height: 80px;
  
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
  
  filter: drop-shadow(0 0 10px rgba(0, 102, 255, 0.3));
  cursor: grab;
  
  &:active {
    cursor: grabbing;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #0066ff;
    transition: all 0.3s ease;
    pointer-events: none;
  }

  &.deco1 { top: 50px; left: 50px; }
  &.deco2 { bottom: 80px; right: 80px; }
  &.deco3 { top: 50%; left: 40%; transform: translate(-50%, -50%); }
`;

const DraggableImage = memo(({ src, alt, className, containerRef }) => {
  const generateRandomFloat = useCallback(() => ({
    x: Math.random() * 30 - 15,
    y: Math.random() * 30 - 15,
    rotate: Math.random() * 15 - 7.5,
    transition: {
      duration: 3,
      ease: "linear",
      repeat: Infinity,
      repeatType: "reverse"
    }
  }), []);

  return (
    <DecoImage
      className={className}
      drag
      whileDrag={{ scale: 1.1, zIndex: 10 }}
      dragMomentum={false}
      dragConstraints={containerRef}
      dragElastic={0.1}
      animate={generateRandomFloat()}
      transition={{ type: "tween" }}
    >
      <img src={src} alt={alt} loading="lazy" />
    </DecoImage>
  );
});

const CodeLine = styled.div`
  color: #8892b0;
  font-family: 'Fira Code', monospace;
  margin-bottom: 1rem;
  
  .comment { color: #64748b; }
  .keyword { color: #ff79c6; }
  .string { color: #64ffda; }
  .function { color: #82aaff; }
`;

const Terminal = styled(motion.div)`
  background: rgba(0, 9, 19, 0.9);
  border: 1px solid #0066ff;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 102, 255, 0.2);
  backdrop-filter: blur(10px);
  
  &::before {
    content: 'system@user:~# ';
    color: #00ccff;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &::after {
    content: 'PRESS START';
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-family: 'Press Start 2P', cursive;
    font-size: 0.8rem;
    color: #00ccff;
    animation: blink 1s step-end infinite;
  }
`;

const TypewriterText = styled(motion.div)`
  color: #00ccff;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  padding-top: 2rem;
  
  .cursor {
    animation: blink 1.5s step-end infinite;
  }
  
  @keyframes blink {
    from, to { border-color: transparent }
    50% { border-color: #00ccff }
  }
`;

const GlitchText = styled.span`
  animation: glitch 500ms infinite;
  font-size: 2.5rem;
  font-weight: bold;
  color: #00ff00;
`;

const Description = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #8892b0;
`;

const PixelBorder = styled.div`
  position: relative;
  padding: 3px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #0066ff;
    clip-path: polygon(
      0 10px, 10px 0,
      calc(100% - 10px) 0, 100% 10px,
      100% calc(100% - 10px), calc(100% - 10px) 100%,
      10px 100%, 0 calc(100% - 10px)
    );
  }
`;

const LevelIndicator = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  color: #00ccff;
  
  &::before {
    content: 'Lv.';
    margin-right: 5px;
  }
`;

const StatusBar = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(0, 102, 255, 0.2);
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background: #00ccff;
    animation: pulse 2s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
`;

const ProfileImage = styled(motion.div)`
  width: 180px;
  height: 180px;
  position: relative;
  margin-bottom: 2rem;
  animation: float 8s ease-in-out infinite;
  will-change: transform;
  
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    height: 100%;
    border: 2px solid #0066ff;
    clip-path: polygon(
      0 15px, 15px 0,
      calc(100% - 15px) 0, 100% 15px,
      100% calc(100% - 15px), calc(100% - 15px) 100%,
      15px 100%, 0 calc(100% - 15px)
    );
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(50%) brightness(110%) contrast(110%);
    border: 2px solid #00ccff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      rgba(0, 102, 255, 0.2),
      transparent 60%
    );
    mix-blend-mode: screen;
  }
`;

const GlitchImage = styled(motion.div)`
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${profileImage});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    will-change: transform;
  }

  &::before {
    left: 2px;
    background-color: #00ccff;
    mix-blend-mode: screen;
  }

  &::after {
    left: -2px;
    background-color: #0035ff;
    mix-blend-mode: screen;
  }
`;

const Hero = memo(() => {
  const constraintsRef = useRef(null);

  return (
    <HeroSection id="hero">
      <BackgroundGrid />
      <BackgroundLines />
      <HeroContent>
        <HeroLeft>
          <PixelBorder>
            <ProfileImage
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <LevelIndicator>99</LevelIndicator>
              <GlitchImage>
                <img src={profileImage} alt="Profile" />
              </GlitchImage>
              <StatusBar />
            </ProfileImage>
          </PixelBorder>

          <Terminal>
            <TypewriterText>
              <GlitchText>{'>'} PLAYER STATUS</GlitchText>
              <br /><br />
              {'>'} Loading save data...<br />
              {'>'} Checking achievements...<br />
              {'>'} Player authenticated<br />
              <br />
              {'>'} Character Stats:<br />
              {'>'} Name: [poteeeet]<br />
              {'>'} Class: Full-Stack Developer<br />
              {'>'} Main Skills: [React.js, Node.js, Python]<br />
              {'>'} Side Skills: [Gaming, Anime, AOKI_TAMI]<br />
              {'>'} Current Quest: Seeking New Adventures<br />
              <br />
              {'>'} Ready Player One...<br />
              <span className="cursor">█</span>
            </TypewriterText>
          </Terminal>
        </HeroLeft>

        <HeroRight>
          <AnimeDecoration
            ref={constraintsRef}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <AnimeStats>
              <div className="stat">
                <span className="label">アニメ力</span>
                <div className="bar"></div>
              </div>
              <div className="stat">
                <span className="label">ゲーム力</span>
                <div className="bar"></div>
              </div>
            </AnimeStats>
            
            <DraggableImage 
              src="/images/favorites/a.png"
              alt="Favorite anime"
              className="deco1"
              containerRef={constraintsRef}
            />
            
            <DraggableImage 
              src="/images/favorites/rocketleague.png"
              alt="Favorite game"
              className="deco2"
              containerRef={constraintsRef}
            />
            
            <DraggableImage 
              src={profileImage}
              alt="Profile"
              className="deco3"
              containerRef={constraintsRef}
            />
          </AnimeDecoration>
        </HeroRight>
      </HeroContent>
    </HeroSection>
  );
});

export default Hero; 