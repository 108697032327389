import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { favorites } from '../data/favorites';

const FavoritesSection = styled.section`
  min-height: 100vh;
  padding: 100px 10%;
  position: relative;
`;

const SectionTitle = styled(motion.h2)`
  font-family: 'Press Start 2P', cursive;
  font-size: 2rem;
  color: #00ccff;
  margin-bottom: 3rem;
  text-shadow: 0 0 10px rgba(0, 204, 255, 0.5);
`;

const CategoryTitle = styled(motion.h3)`
  font-family: 'Press Start 2P', cursive;
  font-size: 1.5rem;
  color: #0066ff;
  margin: 2rem 0;
  
  &::before {
    content: '►';
    margin-right: 1rem;
  }
`;

const FavoritesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const FavoriteCard = styled(motion.div)`
  background: rgba(0, 9, 19, 0.9);
  border: 2px solid #0066ff;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(0, 204, 255, 0.1) 0%,
      transparent 100%
    );
    pointer-events: none;
  }
`;

const FavoriteImage = styled.div`
  position: relative;
  height: 160px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  &:hover img {
    transform: scale(1.1);
  }
`;

const FavoriteInfo = styled.div`
  padding: 1.5rem;
`;

const FavoriteTitle = styled.h4`
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;
  color: #00ccff;
  margin-bottom: 1rem;
`;

const FavoriteComment = styled.p`
  color: #8892b0;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  color: #00ccff;
  padding: 0.2rem 0.5rem;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid #0066ff;
`;

const PlatformBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 102, 255, 0.3);
  padding: 0.3rem 0.6rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  color: #00ccff;
  border: 1px solid #0066ff;
`;

function Favorites() {
  return (
    <FavoritesSection id="favorites">
      <SectionTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        FAVORITES
      </SectionTitle>

      <CategoryTitle
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
      >
        Anime Collection
      </CategoryTitle>
      <FavoritesGrid>
        {favorites.anime.map((anime, index) => (
          <FavoriteCard
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ y: -5 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
          >
            <FavoriteImage>
              <img src={anime.image} alt={anime.title} />
            </FavoriteImage>
            <FavoriteInfo>
              <FavoriteTitle>{anime.title}</FavoriteTitle>
              <FavoriteComment>{anime.comment}</FavoriteComment>
              <TagsContainer>
                {anime.tags.map((tag, i) => (
                  <Tag key={i}>{tag}</Tag>
                ))}
              </TagsContainer>
            </FavoriteInfo>
          </FavoriteCard>
        ))}
      </FavoritesGrid>

      <CategoryTitle
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
      >
        Game Library
      </CategoryTitle>
      <FavoritesGrid>
        {favorites.games.map((game, index) => (
          <FavoriteCard
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ y: -5 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
          >
            <PlatformBadge>{game.platform}</PlatformBadge>
            <FavoriteImage>
              <img src={game.image} alt={game.title} />
            </FavoriteImage>
            <FavoriteInfo>
              <FavoriteTitle>{game.title}</FavoriteTitle>
              <FavoriteComment>{game.comment}</FavoriteComment>
              <TagsContainer>
                {game.tags.map((tag, i) => (
                  <Tag key={i}>{tag}</Tag>
                ))}
              </TagsContainer>
            </FavoriteInfo>
          </FavoriteCard>
        ))}
      </FavoritesGrid>
    </FavoritesSection>
  );
}

export default Favorites; 