import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FiExternalLink } from 'react-icons/fi';
import { projects } from '../data/projects';

const ProjectsSection = styled.section`
  min-height: 100vh;
  padding: 100px 10%;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 60px 5%;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-family: 'Press Start 2P', cursive;
  font-size: 2rem;
  color: #00ccff;
  margin-bottom: 3rem;
  text-shadow: 0 0 10px rgba(0, 204, 255, 0.5);
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled(motion.div)`
  background: rgba(0, 9, 19, 0.9);
  position: relative;
  overflow: hidden;
  border: 2px solid #0066ff;
  backdrop-filter: blur(10px);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, #00ccff, transparent);
  }
`;

const ProjectImage = styled.div`
  position: relative;
  height: 200px;
  overflow: hidden;
  z-index: 1;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 9, 19, 1) 0%,
      rgba(0, 9, 19, 0) 50%
    );
  }

  .scan-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background: rgba(0, 204, 255, 0.5);
    animation: scan 2s linear infinite;
  }

  @keyframes scan {
    0% { top: 0; }
    100% { top: 100%; }
  }
`;

const ProjectInfo = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-family: 'Press Start 2P', cursive;
  font-size: 1rem;
  color: #00ccff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  
  &::before {
    content: '►';
    color: #0066ff;
  }
`;

const ProjectDescription = styled.p`
  color: #8892b0;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const TechStack = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const TechTag = styled.span`
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  color: #00ccff;
  padding: 0.2rem 0.5rem;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid #0066ff;
`;

const ProjectLinks = styled.div`
  display: flex;
  gap: 1rem;
  
  a {
    color: #00ccff;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    
    &:hover {
      color: #0066ff;
      transform: translateY(-2px);
    }
  }
`;

const ProjectStats = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.6rem;
  color: #00ccff;
  background: rgba(0, 9, 19, 0.8);
  padding: 0.5rem;
  border: 1px solid #0066ff;
  border-radius: 4px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 102, 255, 0.3);
`;

function Projects() {
  return (
    <ProjectsSection id="projects">
      <SectionTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        PROJECTS
      </SectionTitle>
      <ProjectGrid>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ y: -5 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
          >
            <ProjectStats>
              <span>{project.stats.level}</span>
              <span>{project.stats.difficulty}</span>
            </ProjectStats>
            <ProjectImage>
              <img src={project.image} alt={project.title} />
              <div className="scan-line" />
            </ProjectImage>
            <ProjectInfo>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <TechStack>
                {project.tech.map((tech, i) => (
                  <TechTag key={i}>{tech}</TechTag>
                ))}
              </TechStack>
              <ProjectLinks>
                <a href={project.live} target="_blank" rel="noopener noreferrer">
                  <FiExternalLink />
                </a>
              </ProjectLinks>
            </ProjectInfo>
          </ProjectCard>
        ))}
      </ProjectGrid>
    </ProjectsSection>
  );
}

export default Projects; 