import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { useState } from 'react';

const Nav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 102, 255, 0.2);
  background: rgba(0, 9, 19, 0.8);
  height: 80px;
  @media (max-width: 768px) {
    height: 70px;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10%;
  @media (max-width: 768px) {
    padding: 1rem 5%;
  }
`;

const Logo = styled(Link)`
  font-family: 'Press Start 2P', cursive;
  color: #00ccff;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  
  &::before {
    content: '>';
    margin-right: 0.5rem;
    opacity: 0.7;
  }

  &:hover {
    color: #00ccff;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  z-index: 100;
  
  @media (max-width: 768px) {
    display: block;
  }

  span {
    display: block;
    width: 100%;
    height: 2px;
    background: #00ccff;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
    
    &:nth-of-type(1) {
      top: ${props => props.isOpen ? '50%' : '20%'};
      transform: ${props => props.isOpen ? 'rotate(45deg)' : 'none'};
    }
    
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
      opacity: ${props => props.isOpen ? '0' : '1'};
    }
    
    &:nth-of-type(3) {
      bottom: ${props => props.isOpen ? '50%' : '20%'};
      transform: ${props => props.isOpen ? 'rotate(-45deg)' : 'none'};
    }

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: rgba(0, 204, 255, 0.2);
      filter: blur(4px);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &:hover span {
    &::before {
      opacity: 1;
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: rgba(0, 9, 19, 0.95);
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 102, 255, 0.2);
    flex-direction: column;
    gap: 0;
    transform: translateY(${props => props.isOpen ? '0' : '-100%'});
    opacity: ${props => props.isOpen ? '1' : '0'};
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
  }
`;

const NavLink = styled(Link)`
  font-family: 'Share Tech Mono', monospace;
  color: #00ccff;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1rem;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 102, 255, 0.1);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  &:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  &.active {
    color: #0066ff;
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #0066ff;
    }
  }
  @media (max-width: 768px) {
    padding: 1rem 5%;
    width: 100%;
    text-align: center;
  }
`;

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <NavContent>
        <Logo
          to="hero"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Poteeeet Official
        </Logo>
        <MobileMenuButton 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          isOpen={isMenuOpen}
        >
          <span />
          <span />
          <span />
        </MobileMenuButton>
        <NavLinks isOpen={isMenuOpen}>
          <NavLink
            to="hero"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >
            HOME
          </NavLink>
          <NavLink
            to="projects"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >
            PROJECTS
          </NavLink>
          <NavLink
            to="skills"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >
            SKILLS
          </NavLink>
          <NavLink
            to="favorites"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >
            FAVORITES
          </NavLink>
        </NavLinks>
      </NavContent>
    </Nav>
  );
}

export default Navigation; 