import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaSteam } from 'react-icons/fa';

const ProfileSection = styled.section`
  min-height: 30vh;
  padding: 100px 10%;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 60px 5%;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-family: 'Press Start 2P', cursive;
  font-size: 2rem;
  color: #00ccff;
  margin-bottom: 3rem;
  text-shadow: 0 0 10px rgba(0, 204, 255, 0.5);
`;

const SteamCard = styled(motion.div)`
  background: rgba(0, 9, 19, 0.9);
  border: 2px solid #0066ff;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, #00ccff, transparent);
  }
`;

const ProfileLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 3rem;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid #0066ff;
  color: #00ccff;
  text-decoration: none;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  
  .icon {
    font-size: 2rem;
  }
  
  &:hover {
    background: rgba(0, 102, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 204, 255, 0.2);
  }
  
  @media (max-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1rem;
    
    .icon {
      font-size: 1.5rem;
    }
  }
`;

function SteamProfile() {
  return (
    <ProfileSection id="steam">
      <SectionTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        GAMING PROFILE
      </SectionTitle>
      <SteamCard
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <ProfileLink 
          href="https://steamcommunity.com/profiles/76561198801919843/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaSteam className="icon" />
          View Steam Profile
        </ProfileLink>
      </SteamCard>
    </ProfileSection>
  );
}

export default SteamProfile; 