import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const FooterContainer = styled.footer`
  background: rgba(0, 9, 19, 0.9);
  border-top: 1px solid rgba(0, 102, 255, 0.2);
  padding: 3rem 10%;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #0066ff, transparent);
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 6rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  h3 {
    font-family: 'Press Start 2P', cursive;
    color: #00ccff;
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  
  a {
    font-family: 'Share Tech Mono', monospace;
    color: #8892b0;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #00ccff;
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 102, 255, 0.1);
  color: #8892b0;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>CONTACT</h3>
          <FooterLinks>
            <a href="mailto:pote2589@gmail.com">Email</a>
            <a href="https://twitter.com/ten3_tami" target="_blank" rel="noopener noreferrer">Twitter</a>
          </FooterLinks>
        </FooterSection>
        
        <FooterSection>
          <h3>LINKS</h3>
          <FooterLinks>
            <a href="#projects">Projects</a>
            <a href="#skills">Skills</a>
            <a href="#favorites">Favorites</a>
          </FooterLinks>
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        © 2024 poteeeet. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
}

export default Footer; 