import { useEffect, useState, memo } from 'react';
import styled from '@emotion/styled';
import { motion, useSpring } from 'framer-motion';

const CursorWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 1000;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CursorOuter = styled(motion.div)`
  position: fixed;
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 204, 255, 0.5);
  border-radius: 50%;
  pointer-events: none;
  
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 1px solid rgba(0, 204, 255, 0.2);
    border-radius: 50%;
    animation: pulseRing 2s infinite;
  }
`;

const CursorInner = styled(motion.div)`
  position: fixed;
  width: 8px;
  height: 8px;
  background: #00ccff;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 10px #00ccff;
`;

const CursorEffect = memo(() => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  // スムーズな動きのためにスプリングアニメーションを使用
  const springConfig = { damping: 25, stiffness: 200 };
  const x = useSpring(0, springConfig);
  const y = useSpring(0, springConfig);

  useEffect(() => {
    const updateMousePosition = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
      x.set(clientX);
      y.set(clientY);
    };

    const updateHoverState = (e) => {
      const isLink = e.target.tagName.toLowerCase() === 'a' ||
                    e.target.tagName.toLowerCase() === 'button' ||
                    e.target.role === 'button';
      setIsHovering(isLink);
    };

    window.addEventListener('mousemove', updateMousePosition);
    document.addEventListener('mouseover', updateHoverState);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      document.removeEventListener('mouseover', updateHoverState);
    };
  }, [x, y]);

  return (
    <CursorWrapper>
      <CursorOuter
        style={{
          x: x,
          y: y,
          scale: isHovering ? 1.5 : 1,
          transform: 'translate(-50%, -50%)'
        }}
        transition={{ scale: { duration: 0.2 } }}
      />
      <CursorInner
        style={{
          x: x,
          y: y,
          transform: 'translate(-50%, -50%)'
        }}
      />
    </CursorWrapper>
  );
});

export default CursorEffect; 